<template>
    <span />
</template>

<script>
import { map, filter, includes, toString, get } from 'utils/lodash';
import http from 'services/axios';
import MapService from 'services/mapservice';
import { mapGetters } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        mapBox: null,
    }),
    computed: {
        ...mapGetters('map', ['isPublic', 'isStyleReady', 'isChangingProvider']),
        fillId() {
            return this.layer.name + '__COMMUTE_RADIUS_FILL__';
        },
        strokeId() {
            return this.layer.name + '__COMMUTE_RADIUS_STROKE__';
        },
        sourceId() {
            return this.layer.name + '__COMMUTE_RADIUS_SOURCE__';
        },
        visibleContours() {
            return filter(this.layer.filters.contours, (r) => r.visible);
        },
        filters() {
            return this.layer?.filters;
        },
        contours() {
            return this.filters.contours_minutes;
        },
        url() {
            let url = new URL(this.layer.url);
            let params = url.searchParams;
            params.set('profile', this.filters.profile);
            params.set('contours_minutes[0]', get(this.contours, 0));
            params.set('contours_minutes[1]', get(this.contours, 1));
            params.set('contours_minutes[2]', get(this.contours, 2));
            url.search = params.toString();
            return url.toString();
        },
    },
    watch: {
        filters: {
            deep: true,
            handler: 'onLayerUpdate',
        },
        map: {
            immediate: true,
            handler: function () {
                this.mapBox = this.map;
            },
        },
    },
    created() {
        this.buildLayers();
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:updated', this.onMapUpdated);
    },
    mounted() {
        this.$events.$on('layer:updated', this.onLayerUpdated);
    },
    beforeDestroy() {
        if (this.isChangingProvider) {
            return;
        }

        this.reset();

        this.$events.$emit('map:popup:leave', this.onPopupLeave);

        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:updated', this.onMapUpdated);
        this.$events.$off('layer:updated', this.onLayerUpdated);
    },
    methods: {
        onLayerUpdated(layer, mapBox) {
            this.mapBox = mapBox;
            if (this.layer.id == layer.id) {
                this.onMapUpdated();
            }
        },
        onMapLoaded(mapBox) {
            this.mapBox = mapBox;

            this.buildLayers();
        },
        onMapUpdated() {
            this.buildLayers();
        },
        async onLayerUpdate() {
            await this.buildLayers();
        },
        async fetch() {
            let response = await http.get(this.url);
            // Filter out the contours that are visible
            let visibleContours = map(this.visibleContours, 'value');

            if (!this.isPublic) {
                return response.data;
            }

            let filtered = filter(response.data?.features, (feature) => {
                return includes(visibleContours, toString(feature.properties.contour));
            });

            return { ...response.data, features: filtered };
        },
        async buildLayers() {
            this.$events.$off('map:styleloaded', this.buildLayers);
            if (!this.isStyleReady) {
                this.$events.$on('map:styleloaded', this.buildLayers);
                return;
            }

            if (this.mapBox.map.getSource(this.sourceId)) {
                return await this.updateSource();
            }

            try {
                let source = this.getSource(await this.fetch());
                // Remove ids
                await this.reset();

                this.mapBox.map.addSource(this.sourceId, source);

                let setups = this.getSetup(source);
                map(setups, (setup) => {
                    this.addMapLayer(setup);
                });
            } catch (e) {
                console.error(e);
            }
        },
        async reset() {
            try {
                if (this.mapBox.map.getLayer(this.fillId)) {
                    this.mapBox.map.removeLayer(this.fillId);
                }

                if (this.mapBox.map.getLayer(this.strokeId)) {
                    this.mapBox.map.removeLayer(this.strokeId);
                }

                if (this.mapBox.map.getSource(this.sourceId)) {
                    await this.mapBox.map.removeSource(this.sourceId);
                }
            } catch (e) {
                console.error(e);
            }
        },
        getSource(data) {
            return {
                type: 'geojson',
                data: data,
                clusterRadius: 14,
                cluster: false,
            };
        },
        getSetup() {
            return [
                {
                    id: this.fillId,
                    type: 'fill',
                    paint: {
                        'fill-color': ['get', 'color'],
                        'fill-opacity': 0.17,
                    },
                    source: this.sourceId,
                },
                {
                    id: this.strokeId,
                    type: 'line',
                    paint: {
                        'line-color': ['get', 'color'],
                        'line-width': 2,
                        'line-opacity': 1,
                    },
                    source: this.sourceId,
                },
            ];
        },
        async addMapLayer(setup) {
            const bottomLayer = MapService.getBottomLayerId();
            MapService.addLayer(setup, bottomLayer);
        },
        async updateSource() {
            try {
                let source = this.getSource(await this.fetch());

                this.mapBox.map.getSource(this.sourceId).setData(source.data);
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
